//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    wheel: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      path: `M 0,54 0,30 c 0,0 24.66284993,-15.028924 70.90569293,-15.028924
 s 76.686043,21.194637 116.763173,21.965352
 s 76.3007,-6.936428 76.3007,-6.936428
 v 30
z`,
      stageIdx: 0,
      activeStages: new Set(),
      stages: [
        {
          id: "pause",
          input: [1,50],
          output: [1,50],
          stepFn: (val,stage) => {
            stage.log(`pause ${val}`);
          }
        },
        {
          id: "wave1",
          input: ["+0", "+400"],
          output: [0, -25],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            this.$refs.wave1.style.transform = `translateY(${val}%)`;
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "text2",
          input: [".", "."],
          output: [100, 33],
          startFn: (val,stage) => {
            // this.$refs.text2.style.visibility = `visible`;
            // this.$refs.text2.style.opacity = 1;
          },
          stepFn: (val,stage) => {
            // safari doesn't handle transform on text. sheesh.
            // this.$refs.text2.style.transform = `translateY(${val}%)`;
            // this.$refs.text2.style.opacity = `${val/10}`;
            this.$refs.text2.setAttribute("y",val);
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "wave2",
          input: ["+100", "+375"],
          output: [0, -25],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            this.$refs.wave2.style.transform = `translateY(${val}%)`;
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "text3",
          input: [".", "."],
          output: [148, 48],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // this.$refs.text3.style.transform = `translateY(${val}%)`;
            // this.$refs.text4.style.transform = `translateY(${val}%)`;
            this.$refs.text3.setAttribute("y",val);
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "text4",
          input: [".", "."],
          output: [163, 63],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // this.$refs.text3.style.transform = `translateY(${val}%)`;
            // this.$refs.text4.style.transform = `translateY(${val}%)`;
            this.$refs.text4.setAttribute("y",val);
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "hideText2",
          input: [".+100", "."],
          output: [10,0],
          stepFn: (val,stage) => {
            const o = val/10;
            stage.log("hideText2",val);
            this.$refs.text2.style.opacity = o;
          }
        },
        {
          id: "wave3",
          input: ["+100", "+350"],
          output: [0, -25],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            this.$refs.wave3.style.transform = `translateY(${val}%)`;
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "text5",
          input: [".", "."],
          output: [178, 78],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // this.$refs.text5.style.transform = `translateY(${val}%)`;
            this.$refs.text5.setAttribute("y",val);
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "hideText34",
          input: [".+100", "."],
          output: [10,0],
          stepFn: (val,stage) => {
            const o = val/10;
            this.$refs.text3.style.opacity = o;
            this.$refs.text4.style.opacity = o;
          }
        },
        {
          id: "wave4",
          input: ["+100", "+325"],
          output: [0, -25],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            this.$refs.wave4.style.transform = `translateY(${val}%)`;
            // this.$refs.bottomWave.style.transform = `translateY(${val}%)`;
            this.$refs.fillerBlock.style.transform = `translateY(${val}%)`;
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "pitch",
          // disabled: true,
          debug: false,
          input: [".", "."],
          output: [0, 10],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // this.$refs.pitchSection.style.transform = `translateY(${val}%)`;
            if (val > 5) {
              this.$refs.pitchSection.classList.add("pitch-section--open");
            }
            else {
              this.$refs.pitchSection.classList.remove("pitch-section--open");
            }
          },
          endFn: (val,stage) => {
          }
        },
        {
          id: "pitchBox",
          debug: false,
          disabled: false,
          input: [".", "."],
          output: [0, 250],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // only roll in once, never roll it back.
            if (!stage._once) {
              this.$refs.pitchBox.style.maxHeight = `${val}px`;
              stage.log("pitchBox.style.maxHeight",`${val}px`);
            }
          },
          endFn: (val,stage) => {
            if (val === 250) {
              stage._once = true;
            }
          }
        },
        {
          id: "pitchText",
          debug: false,
          disabled: false,
          input: [".", "."],
          output: [100, 0],
          startFn: (val,stage) => {
          },
          stepFn: (val,stage) => {
            // only roll in once, never roll it back.
            if (!stage._once) {
              this.$refs.pitchText.style.transform = `translateY(${val}px)`;
            }
          },
          endFn: (val,stage) => {
            if (val === 0) {
              stage._once = true;
            }
          }
        },
        {
          id: "hideText5",
          input: [".+100", "."],
          output: [10,0],
          stepFn: (val,stage) => {
            const o = val/10;
            this.$refs.text5.style.opacity = o;
          }
        },
      ]
    };
  },
  watch: {
    wheel(wheel,_prev) {
      if (this.activeStages.size) {
        // console.log("stages",this.activeStages);
      }

      const stages = this.stages;
      const DEBUG_ALL = false;
      function log(...args) {
        if (this.debug || DEBUG_ALL) {
          console.log.apply(undefined,args);
        }
      }
      stages.forEach((stage,si) => {
        // debug logger for each stage
        stage.log = log.bind(stage);
        // create a cache for parsed input
        if (!stage._input) {
          stage._input = [];
          stage.input.forEach((_input, i) => {
            let val = _input;
            if (typeof _input === "string") {
              // dot means use the same start/end input of previous as base
              if (_input[0] === ".") {
                val = stages[si-1]._input[i];
              }
              else if (i === 0) {
                // if start, use the end of previous as base
                val = stages[si-1]._input[1];
              }
              else {
                // if end, use the start of this as base
                val = stages[si]._input[0];
              }
              // look for a +/- modifier
              const rr = _input.match(/([+-])(\d+)/);
              if (rr) {
                const delta = parseInt(rr[2]);
                val = rr[1] === "+" ? val + delta : val - delta;
              }
            }
            // cache it
            stage._input[i] = val;
          });
        }
        const ratio = (wheel - stage._input[0])/(stage._input[1]-stage._input[0]);
        const pos = stage.output[1] > stage.output[0];
        const delta = Math.abs(Math.round((stage.output[1] - stage.output[0]) * ratio));
        const val = pos ? stage.output[0] + delta : stage.output[0] - delta;
        // don't do any actions if disabled
        if (stage.disabled) {
          stage.log(`ignore disabled stage ${stage.id}`);
          return;
        }
        if (wheel >= stage._input[0] && wheel <= stage._input[1]) {
          if (!this.activeStages.has(stage.id)) {
            this.activeStages.add(stage.id);
            stage.log(`start ${stage.id} at ${val}`);
            stage.startFn && stage.startFn(val,stage);
          }
          if (stage.stepFn) {
            stage.log(`step ${stage.id} at ${val}`);
            window.requestAnimationFrame(stage.stepFn.bind(undefined,val,stage));
          }
        }
        else if (this.activeStages.has(stage.id)) {
          this.activeStages.delete(stage.id);
          // makes sure last value in range gets called at least once
          const lastVal = wheel > _prev ? stage.output[1] : stage.output[0];
          if (stage.stepFn) {
            stage.log(`step ${stage.id} at ${lastVal}`);
            window.requestAnimationFrame(stage.stepFn.bind(undefined,lastVal,stage));
          }

          stage.log(`end ${stage.id} at ${wheel} last val = ${lastVal}`);
          stage.endFn && stage.endFn(lastVal,stage);
        }
      });
    }
  },
  mounted() {

    // this didn't work out great,
    // proves possible to warp the svg,
    // but mouse position erratic, svg position erratic, bleh.
    function warpExperiment() {
      let delta = 100; // start out of range
      function nudge(n) {
        let push = 0;
        const maxPush = 20;
        const farthest = 50;
        const absD = Math.abs(delta);
        if (absD < farthest) {
          // not exactly what I want but close
          // push = maxPush / (Math.sqrt(Math.max(absD,1)));
          push = maxPush * (1 - (absD/farthest));
          // push = delta < 0 ? push : push*-1; // remember q2 coords
          // it should always push down.
        }
        console.log("nudge",push);
        return n+push;
      }
      // just for test try getting just once
      const top = this.$refs.boing.getBoundingClientRect().top;
      console.log("top in mounted",top);
      if (top > 0) {
        window.addEventListener("mousemove", (e) => {
          if (typeof this.$refs?.boing !== "undefined") {
            // unfortunately getBoundingClientRect seems erratic and inaccurate
            // jumps around, gives 0s.
            // const top = this.$refs.boing.getBoundingClientRect().top;
            // console.log("top",top);
            if (top === 0) {
              console.log("top is 0, outta here.");
              return;
            }
            // console.log("mouse", e.clientY,e.clientY);
            delta = e.clientY - this.$refs.boing.getBoundingClientRect().top;
            console.log("delta",delta);
// this approach needs way too much math to work out quickly.
            // this just nudges the first point to see what deflection looks like
            this.path =
              `M 0,54 0,30
 c 0,0 24.66284993,${nudge(-15.028924)} 70.90569293,${nudge(-15.028924)}
 s 76.686043,21.194637 116.763173,21.965352
 s 76.3007,-6.936428 76.3007,-6.936428
 v 30
z`;
          }
        });
      }
    }

  },
  methods:{
    setWaves(step,prev) {
      console.log(`setWaves step to ${step} from ${prev}`);
      switch (step) {
        case 0:
          // remove 1
          this.$refs.wave1.classList.remove("surfwave");
          this.$refs.wave1.classList.remove("easterEggWave");
          this.$refs.text2.classList.remove("revealText");
          break;
        case 1:
          // remove step 2 (if we're going backward)
          this.$refs.wave2.classList.remove("easterEggWave");
          this.$refs.text3.classList.remove("revealText");
          this.$refs.text4.classList.remove("revealText");
          // add step 1
          this.$refs.wave1.classList.remove("surfwave");
          this.$refs.wave1.classList.add("easterEggWave");
          this.$refs.text2.classList.add("revealText");
          break;
        case 2:
          // remove 3:
          this.$refs.wave3.classList.remove("easterEggWave");
          this.$refs.text5.classList.remove("revealText");
          // add step 2
          this.$refs.text2.classList.remove("revealText");
          this.$refs.wave2.classList.add("easterEggWave");
          this.$refs.text3.classList.add("revealText");
          this.$refs.text4.classList.add("revealText");
          break;
        case 3:
          // remove 4:
          this.$refs.pitchSection.classList.remove("pitchWave");
          this.$refs.wave4.classList.remove("easterEggWave");
          this.$refs.bottomWave.classList.remove("easterEggWave");
          this.$refs.fillerBlock.classList.remove("easterEggWave");
          // add 3:
          this.$refs.text3.classList.remove("revealText");
          this.$refs.text4.classList.remove("revealText");
          this.$refs.wave3.classList.add("easterEggWave");
          this.$refs.text5.classList.add("revealText");
          break;
        case 4:
          // remove 3:
          this.$refs.wave3.classList.remove("easterEggWave");
          this.$refs.text5.classList.remove("revealText");
          // add 4:
          this.$refs.text5.classList.remove("revealText");
          this.$refs.pitchSection.classList.add("pitchWave");
          this.$refs.wave4.classList.add("easterEggWave");
          this.$refs.bottomWave.classList.add("easterEggWave");
          this.$refs.fillerBlock.classList.add("easterEggWave");

          // this.$refs.pitchSection.classList.remove("pitchWave");
          // this.$refs.wave4.classList.remove("easterEggWave");
          // this.$refs.bottomWave.classList.remove("easterEggWave");
          // this.$refs.fillerBlock.classList.remove("easterEggWave");
        default:
          break;
      }

    }
  }
};
